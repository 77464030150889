/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { TinySliderSettings } from 'tiny-slider/src/tiny-slider';
import styles from './styles.module.scss';
import getWindowWidth from '../../../utils/getWindowWidth';
import useScreen from '../../../utils/hooks/useScreen';

type IProps = {
  color?: string;
  className?: string;
  containerClassName?: string;
  sliderProps: TinySliderSettings;
  feedbackCount?: number | undefined;
};

const getIsControlsVisible = (
  sliderProps: TinySliderSettings,
  feedbackCount?: number
): boolean => {
  if (feedbackCount && feedbackCount === 1) {
    return false;
  }
  if (!sliderProps.responsive) {
    return !!sliderProps.controls;
  }

  const windowWidth = getWindowWidth();
  const screens = Object.keys(sliderProps.responsive || {});
  const breakpointIndex =
    screens?.map((el) => windowWidth > +el).indexOf(false) - 1;

  if (breakpointIndex < -1)
    return (
      !!sliderProps.controls &&
      !!sliderProps.responsive[+screens[screens.length - 1]]?.controls
    );

  const normalizedBreakpointIndex = breakpointIndex < 0 ? 0 : breakpointIndex;

  return (
    !!sliderProps.controls &&
    !!sliderProps.responsive[+screens[normalizedBreakpointIndex]]?.controls
  );
};

const Slider: FC<IProps> = ({
  children = '',
  containerClassName,
  className,
  color,
  sliderProps,
  feedbackCount,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const isControlsVisible = getIsControlsVisible(sliderProps, feedbackCount);
  const NOTATION = 36;
  const SEGMENT_LENGTH = 7;

  const randomPostfix = useMemo(
    () => Math.random().toString(NOTATION).substring(SEGMENT_LENGTH),
    []
  );
  const classNameWithPrefix = `${className}-${randomPostfix}`;

  useEffect(() => {
    if (!ref.current) return;

    const slider = require('tiny-slider/src/tiny-slider').tns({
      ...sliderProps,
      container: `.${classNameWithPrefix}`,
      prevButton: isControlsVisible ? `#prev-${classNameWithPrefix}` : null,
      nextButton: isControlsVisible ? `#next-${classNameWithPrefix}` : null,
    });

    return () => slider?.destroy();
  }, [ref.current]);

  const { isMobile } = useScreen();

  return (
    <div
      style={{ padding: isControlsVisible && !isMobile ? '0 42px' : '0' }}
      className={classNames(styles.sliderContainer, containerClassName)}
    >
      {isControlsVisible && (
        <>
          <button
            id={`prev-${classNameWithPrefix}`}
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.98811 0L12 2.01429L4.02378 10L12 17.9857L9.98811 20L0 10L9.98811 0Z' fill='%23${color?.slice(
                1
              )}'/%3E%3C/svg%3E%0A")`,
            }}
            data-controls="prev"
          />
          <button
            id={`next-${classNameWithPrefix}`}
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.01189 20L1.76094e-07 17.9857L7.97622 10L1.57236e-06 2.01428L2.01189 -8.73188e-07L12 10L2.01189 20Z' fill='%23${color?.slice(
                1
              )}'/%3E%3C/svg%3E%0A")`,
            }}
            data-controls="next"
          />
        </>
      )}
      <div className={classNameWithPrefix} ref={ref}>
        {children}
      </div>
    </div>
  );
};

export { Slider };
